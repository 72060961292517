var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "claims-date-selector" },
          [
            _c("el-button", {
              attrs: { size: "mini", icon: "el-icon-date", circle: "" },
              on: { click: _vm.today },
            }),
            _c("el-button", {
              attrs: { size: "mini", icon: "el-icon-arrow-left", circle: "" },
              on: { click: _vm.prevDate },
            }),
            _c("div", { staticClass: "claims-date" }, [
              _vm._v(
                " Claims due " +
                  _vm._s(_vm.currentView == "day" ? "on" : "the week of") +
                  " " +
                  _vm._s(_vm._f("dateformat")(_vm.date, "MMM D, YYYY")) +
                  " "
              ),
            ]),
            _c("el-button", {
              attrs: { size: "mini", icon: "el-icon-arrow-right", circle: "" },
              on: { click: _vm.nextDate },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "view-filter" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "mini" },
                model: {
                  value: _vm.currentView,
                  callback: function ($$v) {
                    _vm.currentView = $$v
                  },
                  expression: "currentView",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "day" } }, [
                  _vm._v("Day"),
                ]),
                _c("el-radio-button", { attrs: { label: "week" } }, [
                  _vm._v("Week"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "claims-summary" },
              [
                _c(
                  "download-csv",
                  { attrs: { data: _vm.exportData, name: _vm.csvFilename } },
                  [
                    _c(
                      "el-button",
                      { staticClass: "item export", attrs: { size: "mini" } },
                      [_vm._v(" Export CSV ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "item total",
                    attrs: { disabled: "", size: "mini", type: "primary" },
                  },
                  [
                    _vm._v(
                      " Total due: " +
                        _vm._s(_vm._f("currency")(_vm.total)) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("claim-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading claims due",
          css: { height: "calc(100vh - 200px)" },
          claims: _vm.filteredClaims,
          scroll: true,
          sort: _vm.sort,
        },
        on: { "sort-by": _vm.sortBy, "new-claim": _vm.newClaim },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }