<template>
    <div class="app-container">
        <div class="filter-container">
            <div class="claims-date-selector">
                <el-button
                    size="mini"
                    icon="el-icon-date"
                    circle
                    @click="today"
                />
                <el-button
                    size="mini"
                    icon="el-icon-arrow-left"
                    circle
                    @click="prevDate"
                />
                <div class="claims-date">
                    Claims due
                    {{ currentView == 'day' ? 'on' : 'the week of' }}
                    {{ date | dateformat('MMM D, YYYY') }}
                </div>
                <el-button
                    size="mini"
                    icon="el-icon-arrow-right"
                    circle
                    @click="nextDate"
                />
            </div>

            <div class="view-filter">
                <el-radio-group v-model="currentView" size="mini">
                    <el-radio-button label="day">Day</el-radio-button>
                    <el-radio-button label="week">Week</el-radio-button>
                </el-radio-group>
            </div>

            <div v-if="!loading" class="claims-summary">
                <download-csv :data="exportData" :name="csvFilename">
                    <el-button size="mini" class="item export">
                        Export CSV
                    </el-button>
                </download-csv>

                <el-button
                    disabled
                    size="mini"
                    type="primary"
                    class="item total"
                >
                    Total due: {{ total | currency }}
                </el-button>
            </div>
        </div>

        <claim-list
            v-loading="loading"
            element-loading-text="Loading claims due"
            :css="{height: 'calc(100vh - 200px)'}"
            :claims="filteredClaims"
            :scroll="true"
            :sort="sort"
            @sort-by="sortBy"
            @new-claim="newClaim"
        />

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import ClaimList from '@/views/components/ClaimList';
import ClaimModal from '@/views/components/ClaimModal';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import JsonCSV from 'vue-json-csv';

export default {
    name: 'claims',
    components: {
        ClaimList,
        ClaimModal,
        'download-csv': JsonCSV,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            claims: [],
            loading: true,
            date: moment(),
            currentView: this.view || 'day',
            sort: {
                column: 'date',
                dir: 'asc',
            },

            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
        };
    },
    computed: {
        filteredClaims() {
            // Firebase does not allow inequality searched on multiple fields,
            // so query by date then filter results by status
            return (
                this.claims.filter((c) => c.approval_status !== 'rejected') ||
                []
            );
        },
        total() {
            return this.filteredClaims.reduce(
                (acc, claim) => acc + claim.total,
                0
            );
        },
        csvFilename() {
            return `claims_${this.date.format('YYYY-MM-DD')}.csv`;
        },
        exportData() {
            if (this.filteredClaims && this.filteredClaims.length > 0) {
                return this.filteredClaims.map((claim) => {
                    return {
                        date: moment
                            .unix(claim.date.seconds)
                            .format('DD/MM/YYYY'),
                        code: claim.user.code,
                        user: claim.user.name,
                        provider: claim.provider,
                        invoice: claim.invoice_number,
                        amount: claim.total,
                        created_by: claim.created_by
                            ? claim.created_by.name
                            : '',
                    };
                });
            }
            return [];
        },
    },
    watch: {
        date: function () {
            this.getResults();
        },
        currentView: function (value) {
            if (value === 'day') {
                this.getResults();
            } else {
                this.date = moment(this.date).startOf('week');
                this.getResults();
            }
        },
    },
    mounted() {
        this.getResults();

        // categories for new claim modal
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );
    },
    methods: {
        today() {
            this.date = moment();
            this.currentView = 'day';
            this.getResults();
        },
        getResults() {
            this.loading = true;
            let query = this.getQuery();

            query.get().then((snapshot) => {
                if (snapshot.size > 0) {
                    this.$bind('claims', query, {maxRefDepth: 1})
                        .then(() => {
                            // hide spinner after all data has loaded
                            this.sortResults();
                            this.loading = false;
                        })
                        .catch((e) => {
                            console.log('Claims error: ', e);
                            this.$notify.error({
                                title: 'Error',
                                message: 'Unable to load claims',
                            });
                            this.loading = false;
                            Sentry.captureException(e);
                        });
                } else {
                    this.claims = [];
                    this.loading = false;
                }
            });
        },

        getQuery() {
            let query = this.$fire.collection('claims');

            const start = moment(this.date).startOf('day').toDate();
            let end = moment(this.date).endOf('day').toDate();
            if (this.currentView === 'week') {
                end = moment(start).endOf('week').toDate();
            }

            // select date range
            query = query.where('date', '>=', start).where('date', '<=', end);
            // default initial sort
            query = query.orderBy('date', 'asc');

            return query;
        },

        nextDate() {
            var count = this.currentView === 'day' ? 1 : 7;
            this.date = moment(this.date).add(count, 'days');
        },
        prevDate() {
            var count = this.currentView === 'day' ? 1 : 7;
            this.date = moment(this.date).subtract(count, 'days');
        },

        sortBy(column) {
            if (column != this.sort.column) {
                this.sort.column = column;
                this.sort.dir = 'asc';
            } else {
                this.sort.dir = this.sort.dir == 'asc' ? 'desc' : 'asc';
            }
            this.sortResults();
        },

        sortResults() {
            let claims = [...this.claims];
            claims.sort((a, b) => {
                // assign comparators based on sort order
                let claim_a = this.sort.dir == 'asc' ? a : b;
                let claim_b = this.sort.dir == 'asc' ? b : a;

                // deal with Firebase Timestamp sort first
                if (this.sort.column == 'date') {
                    return claim_a.date.seconds - claim_b.date.seconds;
                }

                // for total sort
                if (this.sort.column == 'total') {
                    return claim_a.total - claim_b.total;
                }

                // for created_by sort, compare user names
                if (this.sort.column == 'created_by') {
                    claim_a = claim_a.created_by ? claim_b.created_by.name : '';
                    claim_b = claim_b.created_by ? claim_b.created_by.name : '';
                    return claim_a.localeCompare(claim_b);
                }

                // for name, compare user names
                if (this.sort.column == 'name') {
                    claim_a = claim_a.user;
                    claim_b = claim_b.user;
                }

                let sort_a = claim_a[this.sort.column] || '';
                let sort_b = claim_b[this.sort.column] || '';

                // all remaining sortable columns are strings
                return sort_a.localeCompare(sort_b);
            });

            this.claims = claims;
        },

        async getActivePlan(user) {
            let planRefs = {};
            let activePlan = false;
            if (user.plans) {
                let queue = [];
                user.plans.forEach((plan) => {
                    planRefs[plan] = plan;
                    let req = this.$fire
                        .doc(plan)
                        .get()
                        .then((snapshot) => {
                            // plan has loaded
                            planRefs['plans/' + snapshot.id] = snapshot.data();

                            // if not yet set, each time a plan loads check for a current plan
                            if (activePlan === false) {
                                const plans = Object.values(planRefs);
                                activePlan =
                                    this.$options.filters.getActivePlan(plans);
                            }
                        });
                    queue.push(req);
                });
                await Promise.all(queue);
            } else {
                activePlan = null;
            }
            return activePlan;
        },

        async newClaim(user) {
            this.user = user;

            let activePlan = await this.getActivePlan(user);
            if (activePlan) {
                this.user_plan = activePlan.data.map((item) => {
                    return {
                        category: item.category.path,
                        budget: item.budget.path,
                    };
                });

                // get assigned categories for user
                const plan_categories = activePlan.data.map((item) => {
                    return item.category.path;
                });
                this.user_categories = this.raw_categories
                    .filter((raw_cat) => {
                        return plan_categories.includes(
                            `categories/${raw_cat.id}`
                        );
                    })
                    .sort((a, b) => {
                        return a.order - b.order;
                    });
                this.$bus.$emit('claim-modal', {user});
            } else {
                this.$notify.error({
                    title: 'No active plan',
                    message: 'Unable to create a claim for this user',
                });
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div {
        margin-right: 10px;
    }

    .view-filter {
        margin-bottom: 10px;
    }

    .claims-date-selector {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .claims-date {
            margin: 0 10px;
        }
    }

    .claims-summary {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .el-button.total {
            cursor: default;
            margin-left: 5px;
        }
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
